<template>
    <div style="padding: 50px 40px">
        <h2>任职要求</h2>
        <p>1. 国贸、英语专业为主；</p>
        <p>2. 英语6级，有一定口语能力；</p>
        <p>3. 24年应届毕业生，可参加6个月实习周期；</p>
        <h2 style="margin-top: 80px">工作内容：</h2>
        <p>1. 与国际电信通道资源供应商在线沟通；</p>
        <p>2. 协助运营维护区域通道资源稳定；</p>
        <h2 style="margin-top: 80px">薪酬福利：</h2>
        <p>1. 150元/工作日（实习前3个月），4500/月（通过实习考核的第4个月开始）</p>
        <p>2. 季度绩效，餐补（公司业绩达标发放），年终奖（实习生根据表现发年终红包）</p>
        <p>3. 双休，法定节假日休息，不加班，每月可申请1天home office；</p>
        <p>4. 团队年轻，工作氛围轻松，每天提供水果或零食；</p>
    </div>
</template>

<script>
export default {
    name: 'Job',
    components: {},
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>

</style>
